import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { formatDate, formatDateWithTime } from "../utils/utils";
import { useAccountState } from "../state/store";
import { form_exclude_keys } from "../utils/form_utils";
import {
  eventDateFormatted,
  eventDateTimeFormatted,
} from "../utils/event_utils";
import { DataGrid } from "@mui/x-data-grid";
import { NewEventDialog } from "./Dashboard";
import { addUserEvent, addUserEventFromFormSubmission } from "../api/EventsApi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ccFields, cdFields, tiFields } from "./Form";
import _ from "lodash";
import { useLocaleContext } from "./locale";

const FormSubmissions = (props) => {
  const locale = useLocaleContext();
  const [form_submissions] = useAccountState((state) => [
    state.form_submissions,
  ]);

  const [viewingInitialFormSubmission, setViewingInitialFormSubmission] =
    React.useState(false);

  React.useEffect(() => {
    if (props.initial_form_submission_uuid) {
      setViewingInitialFormSubmission(true);
    }
  }, [props.initial_form_submission_uuid, form_submissions]);

  const columns = [
    {
      field: "submitted_on",
      headerName: "Submitted",
      flex: 3,
      hideable: false,
      renderCell: RenderSubmittedOn,
      locale: locale,
      type: "date",
    },
    {
      field: "submitted_by",
      headerName: "Submitted By",
      flex: 4,
      renderCell: RenderSubmittedBy,
      sortable: true,
      hideable: false,
    },
    {
      field: "event",
      headerName: "Event",
      flex: 4,
      renderCell: RenderEvent,
      sortable: true,
      hideable: false,
    },
    {
      field: "event_date",
      headerName: "Event Date",
      flex: 2,
      renderCell: RenderEventDate,
      sortable: true,
      hideable: false,
      type: "date",
      locale: locale,
    },
    {
      field: "actions",
      headerName: "",
      flex: 3,
      renderCell: RenderActions,
      sortable: false,
      hideable: false,
    },
  ];

  return (
    <Grid container justifyContent="space-between" spacing={1}>
      <Grid item xs="auto">
        <Typography variant="h6">Form Submissions</Typography>
      </Grid>
      {form_submissions && form_submissions.length > 0 ? (
        <Grid item xs={12}>
          <DataGrid
            rows={form_submissions}
            columns={columns}
            getRowId={getRowId}
            autoHeight
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            // disable
            rowHeight={55}
            density="compact"
            initialState={{
              sorting: {
                sortModel: [{ field: "submitted_on", sort: "desc" }],
              },
            }}
            sx={{ border: "none" }}
          />
          {props.initial_form_submission_uuid && (
            <FormSubmissionDetailsDialog
              form_submission={form_submissions.find(
                (fs) => fs.uuid === props.initial_form_submission_uuid
              )}
              open={viewingInitialFormSubmission}
              handleCancel={() => {
                setViewingInitialFormSubmission(false);
                props.setFormSubmissionUuid(undefined);
              }}
            />
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              m: "1rem",
              p: "1rem",
              border: "dashed 2px",
              borderRadius: "5px",
              borderColor: "info.main",
            }}
          >
            <Typography textAlign={"center"}>
              No form submissions have been received.
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const getRowId = (row) => {
  return row.uuid;
};

const RenderSubmittedOn = (props) => {
  return (
    <Typography variant="body1" sx={{ fontSize: ".75rem" }}>
      {formatDateWithTime(props.row.submitted_on, props.colDef.locale)}
    </Typography>
  );
};

const RenderFormName = (props) => {
  return <Typography variant="body1">{props.row.form.name}</Typography>;
};

const RenderSubmittedBy = (props) => {
  const [copied, setCopied] = React.useState(false);
  return (
    <>
      <Typography variant="body1">
        <CopyToClipboard
          text={JSON.parse(props.row.data)["Email"]}
          onCopy={() => {
            setCopied(true);
          }}
        >
          <Tooltip
            title={`${JSON.parse(props.row.data)["Email"]} (click to copy)`}
          >
            <Link
              sx={{
                cursor: "pointer",
                textDecoration: "none", // Remove underline by default
                "&:hover": {
                  textDecoration: "underline", // Add underline on hover
                },
              }}
              color="inherit"
            >
              {JSON.parse(props.row.data)["Name"]}
            </Link>
          </Tooltip>
        </CopyToClipboard>
      </Typography>
      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => {
          setCopied(false);
        }}
        message="Email copied!"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </>
  );
};

const RenderEvent = (props) => {
  return (
    <Typography variant="body1">
      {props.row.event ? (
        <Link href={`/event/${props.row.event.uuid}`} color="inherit">
          {props.row.event.name}
        </Link>
      ) : (
        "-"
      )}
    </Typography>
  );
};

const RenderEventDate = (props) => {
  return (
    <Typography variant="body1" sx={{ fontSize: ".75rem" }}>
      {props.row.event
        ? formatDate(
            eventDateFormatted(props.row.event.date, props.row.timezone),
            props.colDef.locale
          )
        : formatDate(
            JSON.parse(props.row.data)["Event Date"],
            props.colDef.locale
          )}
    </Typography>
  );
};

const RenderActions = (props) => {
  const [deleteFormSubmission, eventTemplates] = useAccountState((state) => [
    state.deleteFormSubmission,
    state.eventTemplates,
  ]);
  const [viewing, setViewing] = React.useState(false);
  const [creatingEvent, setCreatingEvent] = React.useState(false);
  const history = useHistory();
  const handleAddEvent = (data) => {
    addUserEventFromFormSubmission({
      ...data,
      form_submission_uuid: props.row.uuid,
    }).then((resp) => {
      window.location.href = "/event/" + resp.data.uuid;
    });
  };
  const [deleting, setDeleting] = React.useState(false);
  const handleDelete = () => {
    deleteFormSubmission(props.row.uuid);
  };
  return (
    <Grid container justifyContent={"flex-end"} alignItems={"center"}>
      <Grid item xs="auto">
        <Tooltip title="View submission data">
          <IconButton onClick={() => setViewing(true)}>
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
        <FormSubmissionDetailsDialog
          form_submission={props.row}
          open={viewing}
          handleCancel={() => setViewing(false)}
        />
      </Grid>
      <Grid item xs="auto">
        <Tooltip title="Create event from submission data">
          <IconButton onClick={() => setCreatingEvent(true)}>
            <NoteAddOutlinedIcon />
          </IconButton>
        </Tooltip>
        <NewEventDialog
          open={creatingEvent}
          handleCancel={() => setCreatingEvent(false)}
          handleSubmit={handleAddEvent}
          initialEventDate={JSON.parse(props.row.data)["Event Date"]}
          initialEventName={`${JSON.parse(props.row.data).Name} Event`}
          templates={eventTemplates}
        />
      </Grid>
      <Grid item xs="auto">
        <Tooltip title="Permanantly delete form submission">
          <IconButton onClick={() => setDeleting(true)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
        <DeleteFormSubmissionDialog
          form_submission={props.row}
          open={deleting}
          handleCancel={() => setDeleting(false)}
          handleSubmit={handleDelete}
        />
      </Grid>
    </Grid>
  );
};

const FormSubmissionDetailsDialog = (props) => {
  const [data, setData] = React.useState({});
  React.useEffect(() => {
    setData(JSON.parse(props.form_submission.data));
  }, [props.form_submission]);
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Form Details: {props.form_submission.form.name}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.form_submission.fields
              ? props.form_submission.fields.map((field) => {
                  return (
                    <>
                      {["GT", "GN", "MC"].includes(field.type) && (
                        <FormSubmissionDetailGeneric
                          field={field}
                          data={data}
                        />
                      )}
                      {["ED"].includes(field.type) && (
                        <FormSubmissionDetailDate field={field} data={data} />
                      )}
                      {["RQ"].includes(field.type) && (
                        <FormSubmissionDetailRecipeQuantity
                          field={field}
                          data={data}
                        />
                      )}
                      {["CD"].includes(field.type) && (
                        <FormSubmissionDetailClientContact
                          field={field}
                          data={data}
                        />
                      )}
                      {["CC"].includes(field.type) && (
                        <FormSubmissionDetailProfessionalContact
                          field={field}
                          data={data}
                        />
                      )}
                      {["TI"].includes(field.type) && (
                        <FormSubmissionDetailTimelineItem
                          field={field}
                          data={data}
                          timezone={props.form_submission.timezone}
                        />
                      )}
                    </>
                  );
                })
              : Object.keys(data)
                  .filter(
                    (key) =>
                      !form_exclude_keys.includes(key) &&
                      !key.endsWith("_address") &&
                      data[key] !== ""
                  )
                  .sort()
                  .map((key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{key.replace(/_/g, " ")}</TableCell>
                        <TableCell>
                          {key === "Event Date" && props.form_submission.event
                            ? eventDateFormatted(
                                props.form_submission.event.date,
                                props.form_submission.timezone
                              )
                            : data[key]}
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FormSubmissionDetailGeneric = ({ field, data }) => {
  return (
    <TableRow key={field.uuid}>
      <TableCell>{field.name}</TableCell>
      <TableCell>
        {data[field.uuid] ? data[field.uuid] : data[field.name]}
      </TableCell>
    </TableRow>
  );
};

const FormSubmissionDetailDate = ({ field, data }) => {
  return (
    <TableRow key={field.uuid}>
      <TableCell>{field.name}</TableCell>
      <TableCell>
        {eventDateFormatted(
          data[field.uuid] ? data[field.uuid] : data[field.name],
          data.timezone
        )}
      </TableCell>
    </TableRow>
  );
};

const FormSubmissionDetailRecipeQuantity = ({ field, data }) => {
  return (
    <>
      <TableRow key={field.uuid}>
        <TableCell>{field.name}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              {field.recipes.map((recipe) => {
                return (
                  <TableRow key={field.uuid + recipe.uuid}>
                    <TableCell>{recipe.name}</TableCell>
                    <TableCell>
                      {data[`${field.uuid}_${recipe.uuid}_quantity`]}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

const FormSubmissionDetailClientContact = ({ field, data }) => {
  return (
    <>
      <TableRow key={field.uuid}>
        <TableCell>{field.name}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              {cdFields
                .filter((ccField) => data[`${field.uuid}_${ccField.name}`])
                .map((ccField) => {
                  return (
                    <TableRow key={field.uuid + ccField.name}>
                      <TableCell>{ccField.label}</TableCell>
                      <TableCell>
                        {data[`${field.uuid}_${ccField.name}`]}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

const FormSubmissionDetailProfessionalContact = ({ field, data }) => {
  return (
    <>
      <TableRow key={field.uuid}>
        <TableCell>{field.name}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              {ccFields
                .filter((ccField) => data[`${field.uuid}_${ccField.name}`])
                .map((ccField) => {
                  return (
                    <TableRow key={field.uuid + ccField.name}>
                      <TableCell>{ccField.label}</TableCell>
                      <TableCell>
                        {data[`${field.uuid}_${ccField.name}`]}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

const FormSubmissionDetailTimelineItem = ({ field, data, timezone }) => {
  return (
    <>
      <TableRow key={field.uuid}>
        <TableCell>{field.name}</TableCell>
        <TableCell>
          <Table>
            <TableBody>
              {tiFields
                .filter(
                  (tiField) =>
                    data[`${field.uuid}_${tiField.name}`] &&
                    !_.isEmpty(data[`${field.uuid}_${tiField.name}`])
                )
                .map((tiField) => {
                  return (
                    <TableRow key={field.uuid + tiField.name}>
                      <TableCell>{tiField.label}</TableCell>
                      <TableCell>
                        {tiField.name === "address" &&
                          data[`${field.uuid}_${tiField.name}`]?.description}
                        {tiField.name === "datetime" &&
                          eventDateTimeFormatted(
                            data[`${field.uuid}_${tiField.name}`],
                            timezone
                          )}
                        {tiField.name === "name" &&
                          data[`${field.uuid}_${tiField.name}`]}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    </>
  );
};

const DeleteFormSubmissionDialog = (props) => {
  const locale = useLocaleContext();
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Form Submission</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the form submission from{" "}
          {JSON.parse(props.form_submission.data).Name} submitted on{" "}
          {formatDateWithTime(props.form_submission.submitted_on, locale)}? This
          action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button onClick={props.handleSubmit} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(FormSubmissions);
