export const form_field_types = {
  CD: {
    name: "Contact Details",
    description: "Clients can provide their contact information.",
  },
  ED: {
    name: "Event Date",
    description:
      "Clients can provide a key date for their event. The first event date field will be used to populate the date when an event is created from a form submission.",
  },
  GT: {
    name: "Generic Text",
    description:
      "Clients can input text answers. You can configure the answer to populate the private event details or the client facing proposal notes. You can also configure the answer to be a single line or a paragraph.",
  },
  GN: {
    name: "Generic Number",
    description:
      "Clients can input numbers. You can configure the answer to be an integer or a decimal.",
  },
  MC: {
    name: "Multiple Choice",
    description: "Clients can select one option from a drop down menu.",
  },
  MS: {
    name: "Multiple Select",
    description: "Clients can select multiple options from a list.",
  },
  CC: {
    name: "Professional Contact",
    description:
      "Clients can provide contact information of one of their vendors (e.g. their wedding planner).",
  },

  RQ: {
    name: "Recipe Quantity",
    description:
      "Clients can provide an integer that will populate the quantity of a recipe in the event. You can select which recipe from the list of recipes in the chosen event template.",
  },
  TI: {
    name: "Timeline Item",
    description:
      "Clients can provide the date, time, and location of an event (e.g. cocktail hour, 5:00pm, The Barn).",
  },
};

export const form_exclude_keys = ["form_uuid"];
